

















































































































































































































































































































































































































































.v-chip--outlined.v-chip--active::before {
  opacity: 0;
}
